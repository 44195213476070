import styled from 'styled-components';
import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import Layout from 'src/components/Layout';
import SEO from 'src/components/SEO';
import IconBlock from 'src/components/IconBlock';
import {
  ShopIcon,
  AllIcon,
  EasyUseIcon,
  TrackingIcon,
  RealTimeIcon,
  ReportsIcon,
} from 'src/assets/icons';
import Grid from 'src/components/Grid';
import Section from 'src/components/Section';
import { colors } from 'src/theme/colors';
import Content from 'src/components/Content';
import List from 'src/components/List';
import { device } from 'src/theme/breakpoints';
import Heading, { HeadingVariant } from 'src/components/Heading';
import Link, { LinkVariant } from 'src/components/Link';
import { LINKS } from 'src/utils/constants';
import Price from 'src/components/Price';

const StyledGrid = styled(Grid)`
  margin-top: 30px;
  row-gap: 30px;

  @media ${device.laptopSUP} {
    margin-top: 50px;
    row-gap: 50px;
  }

  @media ${device.desktop} {
    margin-top: 70px;
    row-gap: 70px;
  }
`;

const DarkBackgroundSection = styled.div`
  background-color: ${colors.blue800};
  clip-path: polygon(0 2%, 100% 0, 100% 98%, 0% 100%);
  color: ${colors.white};
  margin-top: 40px;
  padding: 50px 0 70px;
  width: 100%;

  @media ${device.laptopUP} {
    clip-path: polygon(0 6%, 100% 0, 100% 94%, 0% 100%);
    margin-top: 120px;
    padding: 110px 0 160px;
  }
`;

const Hero = styled.div`
  background-color: ${colors.grey200};
  clip-path: polygon(0 0, 100% 0, 100% 94%, 0% 100%);
  height: auto;
  margin-top: -120px;
  max-height: 640px;
  padding-bottom: 40px;
  position: relative;
  width: 100%;

  @media ${device.laptopSUP} {
    max-height: 800px;
    padding-bottom: 100px;
  }
`;

const HeroWrapper = styled(Content)`
  padding-top: 120px;

  @media ${device.laptopSUP} {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    padding-top: 180px;
  }
`;

const HeroContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  z-index: 1;

  @media ${device.laptopUP} {
    max-width: 1000px;
  }
`;

const HeroList = styled(List)`
  margin: 40px 0 14px;
  max-width: 640px;

  @media ${device.mobileDOWN} {
    margin-top: 20px;
  }
`;

const HeroImageWrapper = styled.div`
  flex: 0 1 auto;
  max-width: 320px;
  width: 100%;

  @media ${device.tabletDOWN} {
    display: none;
  }
`;

const Subheading = styled.p`
  font-weight: 700;
  margin: 0;

  @media ${device.laptopSUP} {
    font-size: 24px;
  }
`;

const StyledPrice = styled(Price)`
  align-self: center;
`;

const EnterpriseBlock = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const CardsWrapper = styled(Grid)`
  margin: 40px 0 80px;

  @media ${device.laptopUP} {
    margin: 70px 0 160px;
  }
`;

const Card = styled.div`
  background-color: ${colors.white};
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
  color: ${colors.black};
  padding-bottom: 40px;
  text-align: center;
`;

const CardHeading = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.grey200};
  display: flex;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  padding: 12px 0;
  width: 100%;

  @media ${device.laptopUP} {
    font-size: 20px;
    padding: 12px 0;
  }
`;

const CardText = styled.p`
  font-weight: 700;
  margin: 0 0 12px;
  padding-top: 20px;
`;

const DataScraperPage: FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Hero>
        <HeroWrapper>
          <HeroContent>
            <Heading variant={HeadingVariant.BRAVO}>
              <Trans i18nKey="data_tool_main_heading">
                Outsmart any competitor in your industry
              </Trans>
            </Heading>
            <Subheading>
              <Trans i18nKey="data_tool_main_subheading">
                The tool to track & monitor data in digital channels
              </Trans>
            </Subheading>
            <HeroList
              items={[
                { text: t('data_tool_main_list_item_1') },
                { text: t('data_tool_main_list_item_2') },
              ]}
            />
          </HeroContent>
          <HeroImageWrapper>
            <StaticImage
              layout="fullWidth"
              alt={t('index_first_section_image_alt')}
              src="../assets/images/competition.png"
              formats={['auto', 'webp']}
            />
          </HeroImageWrapper>
        </HeroWrapper>
      </Hero>
      <Section title={t('data_tool_section_1_title')}>
        <StyledGrid columns={2}>
          <IconBlock
            icon={<ShopIcon />}
            title={t('data_tool_section_1_icon_1_title')}
          >
            <p>
              <Trans i18nKey="data_tool_section_1_icon_1_text">
                Knowledge is power. Thanks to the solution, online retailers are
                aware of competitors&apos; product prices in real-time so that
                they can make data-driven decisions about adjusting their own.
              </Trans>
            </p>
          </IconBlock>
          <IconBlock
            icon={<AllIcon />}
            title={t('data_tool_section_1_icon_2_title')}
          >
            <p>
              <Trans i18nKey="data_tool_section_1_icon_2_text">
                With the help of this tool, brands and distributors can monitor
                if their clients are respecting prices equally throughout
                distribution channel.
              </Trans>
            </p>
          </IconBlock>
        </StyledGrid>
      </Section>
      <DarkBackgroundSection>
        <Section
          title={t('data_tool_section_2_title')}
          subtitle={t('data_tool_section_2_subtitle')}
          isDark
        >
          <StyledGrid columns={4}>
            <IconBlock
              icon={<EasyUseIcon />}
              title={t('data_tool_section_2_icon_1_title')}
              isOnDark
            >
              <p>
                <Trans i18nKey="data_tool_section_2_icon_1_text">
                  The tool is a comprehensive and complex piece of technology.
                  And yet it is simple to use - no prior technical knowledge is
                  neccessary. No manual work - the is no need to collect
                  products&apos; URLs and feed them to the tool. Everything is
                  done automatically
                </Trans>
              </p>
            </IconBlock>
            <IconBlock
              icon={<TrackingIcon />}
              title={t('data_tool_section_2_icon_2_title')}
              isOnDark
            >
              <p>
                <Trans i18nKey="data_tool_section_2_icon_2_text">
                  The tool monitors pricing in any currency on any marketplace.
                  It is capable of tracking even bot-proof websites. No
                  limitations on amount of websites, you can track as many as
                  you need to.
                </Trans>
              </p>
            </IconBlock>
            <IconBlock
              icon={<RealTimeIcon />}
              title={t('data_tool_section_2_icon_3_title')}
              isOnDark
            >
              <p>
                <Trans i18nKey="data_tool_section_2_icon_3_text">
                  Set your own pricing rules for each category, brand or even an
                  individual item and determine what the best prices should be
                  compared to competitors. By adjusting prices according to the
                  product availability (both yours and competitors) for
                  optimizing sales, you can greatly increase your profits.
                </Trans>
              </p>
            </IconBlock>
            <IconBlock
              icon={<ReportsIcon />}
              title={t('data_tool_section_2_icon_4_title')}
              isOnDark
            >
              <p>
                <Trans i18nKey="data_tool_section_2_icon_4_text">
                  You&apos;ll be able to see your competitor stock dynamics and
                  price insights over certain periods, so you know how
                  they&apos;re trading or where opportunities may lie for
                  getting ahead of them. These reports come with detailed
                  statistics that are easy to understand on a user-friendly
                  dashboard.
                </Trans>
              </p>
            </IconBlock>
          </StyledGrid>
        </Section>
      </DarkBackgroundSection>
      <Section
        title={t('data_tool_section_3_title')}
        subtitle={t('data_tool_section_3_subtitle')}
      >
        <CardsWrapper columns={4}>
          <Card>
            <CardHeading>Standard</CardHeading>
            <CardText>
              5 000{' '}
              <Trans i18nKey="common_queries_a_month"> queries*/ month</Trans>
            </CardText>
            <StyledPrice
              price="99"
              monthly={true}
              bottomText={`+ 0,02€ ${t('common_additional_queries')}`}
            />
          </Card>
          <Card>
            <CardHeading>Plus</CardHeading>
            <CardText>
              50 000{' '}
              <Trans i18nKey="common_queries_a_month"> queries*/ month</Trans>
            </CardText>
            <StyledPrice
              price="399"
              monthly={true}
              bottomText={`+ 0,01€ ${t('common_additional_queries')}`}
            />
          </Card>
          <Card>
            <CardHeading>Complete</CardHeading>
            <CardText>
              500 000{' '}
              <Trans i18nKey="common_queries_a_month"> queries*/ month</Trans>
            </CardText>
            <StyledPrice
              price="999"
              monthly={true}
              bottomText={`+ 0,005€ ${t('common_additional_queries')}`}
            />
          </Card>
          <EnterpriseBlock>
            <p>
              <Trans i18nKey="data_tool_section_3_text">
                For enterprice customers with the need for more queries, contact
                sales
              </Trans>
            </p>
            <Link url={LINKS.CONTACTS} variant={LinkVariant.BRAVO}>
              <Trans i18nKey="common_contact_sales">Contact sales</Trans>
            </Link>
          </EnterpriseBlock>
        </CardsWrapper>
      </Section>
    </Layout>
  );
};

export default DataScraperPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => <SEO title="Data Scraper | Instegral" />;
